import { createSlice } from '@reduxjs/toolkit'
import { post, patch } from './slice'

export const auth = createSlice({
  name: 'auth',
  initialState: {
    isLogin: false,
    token: '',
    data: {
      id: '',
      name: '',
      email: '',
      password: '',
      roleId: 0,
      phone: '',
    },
    sidebarShow: true,
    exit: 0,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isLogin = true
      state.token = action.payload.token
      state.data = action.payload
    },
    logout: (state) => {
      state.isLogin = false
      state.data = {
        id: '',
        name: '',
        email: '',
        password: '',
        roleId: 0,
        phone: '',
      }
    },
    setSidebar: (state, action) => {
      state.sidebarShow = action.payload ?? false
    },
  },
})

export const { loginSuccess, logout, setSidebar } = auth.actions

export default auth.reducer

// ---------------- ACTION ---------------

// import {errorMessage} from '../../utils';
const defaultBody = null

export const Logout = () => (dispatch) => {
  dispatch(logout())
}
