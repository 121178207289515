import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import auth from './features/authSlice'
import main from './features/slice'
import reducers from '../reducers'

const persistConfig = {
  key: 'root',
  storage,
}

const reducer = combineReducers({
  auth,
  main,
  ...reducers,
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)
