/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import Axios from 'axios'
import { loginSuccess } from './authSlice'
import { BaseUrl } from '../../utilities/BaseUrl'
import { logout } from './authSlice'

export const slice = createSlice({
  name: 'main',
  initialState: {},
  reducers: {},
})

export const {} = slice.actions

export const post =
  (link, data, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.post(BaseUrl + link, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.isLogin == true ? 'Bearer ' + auth.token : '',
      },
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        ifError(err)
      })
      .finally(() => {
        finallyDo()
      })
  }

export const postFormData =
  (link, data, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.post(BaseUrl + link, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: auth.isLogin == true ? 'Bearer ' + auth.token : '',
      },
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        ifError(err)
      })
      .finally(() => {
        finallyDo()
      })
  }

export const patch =
  (link, data, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.patch(BaseUrl + link, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.isLogin == true ? 'Bearer ' + auth.token : '',
      },
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        ifError(err)
      })
      .finally(() => {
        finallyDo()
      })
  }

export const put =
  (link, data, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.put(BaseUrl + link, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.isLogin == true ? 'Bearer ' + auth.token : '',
      },
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        ifError(err)
      })
      .finally(() => {
        finallyDo()
      })
  }

export const get =
  (link, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.get(BaseUrl + link, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.isLogin == true ? 'Bearer ' + auth.token : '',
      },
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        // console.log(err)
        // if (err.response?.status === 403) {
        //   dispatch(logout())
        // } else {
        ifError(err)
        // }
      })
      .finally(() => {
        finallyDo()
      })
  }

export const getCustom =
  (link, header, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.get(link, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...header,
      },
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        // if (err.response?.status === 403) {
        //   dispatch(logout())
        // } else {
        ifError(err)
        // }
      })
      .finally(() => {
        finallyDo()
      })
  }

export const deleted =
  (link, ifSuccess = () => {}, ifError = () => {}, finallyDo = () => {}) =>
  async (dispatch, getState) => {
    const { auth } = getState()
    await Axios.delete(BaseUrl + link, {
      headers: {
        'Content-Type': 'application/json',
        token: auth.token,
      },
      data: {},
    })
      .then((res) => {
        ifSuccess(res)
      })
      .catch((err) => {
        // if (err.response?.status === 403) {
        //   dispatch(logout())
        // } else {
        ifError(err)
        // }
      })
      .finally(() => {
        finallyDo()
      })
  }

export default slice.reducer
